.album {
    .masonry.card-columns {
        column-count: 2;
        @include media-breakpoint-up(sm) {
            column-count: 2;
        }
        @include media-breakpoint-up(md) {
            column-count: 2;
        }
        @include media-breakpoint-up(lg) {
            column-count: 3;
        }
        @include media-breakpoint-up(xl) {
            column-count: 4;
        }
    }
}
.masonry.card-columns {
    column-gap: 6px;   
    @include media-breakpoint-down(sm) {
        column-count: 2;
    }
    .item {
        .card {
            border-radius: 3px;
            margin-bottom: 0;
            @include media-breakpoint-down(xs) {
                margin-bottom: 6px;
            }
            .card-image {
                position: relative;
                &:before {
                    content: "\f002";
                    font-family: "Font Awesome 5 Pro";
                    position: absolute;
                    right: 15px;
                    bottom: 10px;
                    color: $white;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 1;
                }
            }
            .card-img-overlay {
                top: auto;
                padding: 15px;
                background: transparent;
                .card-caption {
                    .card-title {
                        margin-bottom: 0;
                        font-size: 13px;
                        text-shadow: none;
                    }
                    .card-subtitle {
                        margin-bottom: 0;
                        font-size: 24px;
                        text-shadow: none;
                    }                
                }
                .card-buttons {
                    @extend .d-none;
                }
            }
        }
    }
}