.header {

	/* header-main */
	.header-main {
		background: $gray-dark;
		@include media-breakpoint-up(xl) {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
			min-height: 180px;
			transition: 0.5s;
		}

		.navbar {
			@include media-breakpoint-up(xl) {
				padding: 20px 30px;
				align-items: center;
				justify-content: center;
				transition: 0.5s;
			}

			.navbar-toggler {
				margin-left: -0.75rem;
				color: $white;
				border: none;
				outline: none;
			}

			.menu {
				@include media-breakpoint-down(lg) {
					order: 3;
				}
			}

			.navbar-nav {
				margin-left: 0;

				.nav-item {
					margin-right: 5px;
					font-family: $font-family-didact-gothic;

					.nav-link {
						color: $white;

						&:hover {
							text-decoration: underline;
						}
					}

					&.active {
						text-decoration: underline;
					}

					.dropdown-menu {
						margin: 0;
						padding: 0;
						background: rgba($black, 0.9);

						.nav-item {
							margin-right: 0;

							.nav-link {
								padding-left: 0.5rem;
							}
						}
					}
				}
			}
		}

		.logo {
			max-width: 150px;
			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				max-width: 240px;
				margin: 20px auto;
			}
		}

		.socials {
			@include media-breakpoint-down(lg) {
				display: none;
			}

			.nav-item {
				margin: 5px;

				.nav-link {
					display: block;
					width: 36px;
					height: 36px;
					padding: 0 !important;
					border-radius: 100%;
					color: $white;
					font-size: 24px;
					line-height: 36px;
					text-align: center;

					i {
						line-height: 36px;
					}

					&:hover {
						background: $gray-dark;
						color: $white;
					}
				}
			}
		}

		.quick-button {
			margin-bottom: 0;

			li {
				margin: 0 0 0 10px;

				a {
					@extend .btn;
					min-width: 220px !important;

					&:hover {
						background-color: transparent;
						border-color: $white;
						color: $white;
					}
				}
			}

			@include media-breakpoint-down(lg) {
				display: none !important;
			}
		}
	}

	/* header-team  */
	.header-team {
		position: relative;
		min-height: 65px;
		background: $gray-dark;
		@include media-breakpoint-up(xl) {
			display: none;
		}

		h6 {
			color: $white;
			font-size: 13px;
			text-align: center;
		}

		.team-blocks {
			position: absolute;
			top: 25px;
			left: 0;
			right: 0;
			max-width: 330px;
			margin: 0 auto 0 auto;

			.item {
				.card {
					.card-image {
						width: 100%;
						height: auto;
						border-radius: 100%;
						overflow: hidden;

						.card-img {
							width: 100%;
							height: auto;
							border-radius: 100%;
							overflow: hidden;
						}
					}

					.card-body {
						display: none !important;
					}
				}
			}
		}
	}

	/*  sticky
		========================================================================== */
	@include media-breakpoint-up(xl) {
		&.sticky {
			box-shadow: none;

			.header-main {
				min-height: 85px;
				background: #4a4a4e;

				.logo {
					max-width: 150px;
				}
			}
		}
	}

}

#carousel_1532 {
	@Media(max-width: 767px) {
		margin-top: -15px;
	}
}
