&.service_detail {
    .service_detail {
        .info {
            @include media-breakpoint-up(lg) {
                @include make-col(12);
            }
            padding-right: 15px !important;
        }
        .info-extra {
            @include media-breakpoint-up(lg) {
                @include make-col(12);
            }
            padding-left: 15px !important;
            .gallery {
                .gallery-item {
                    &.large-item {
                        @include make-col(4);
                    }
                    .gallery-link {
                        &:before {
                            content: "\f002";
                            font-family: "Font Awesome 5 Pro";
                            position: absolute;
                            right: 15px;
                            bottom: 10px;
                            color: $white;
                            font-size: 20px;
                            font-weight: 300;
                            line-height: 1;
                        }
                        .gallery-img {
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
    }
}