.page_block.blog_overview,
.page_block.service_overview,
.page_block.assortiment_overview,
.page_block.accommodation_overview {
    &.grid {
        .item {
            @include media-breakpoint-up(xl) {
                @include make-col(4);
            }
        }
    }
}