/*  card
    ========================================================================== */
    .card {
        .card-body {
            .card-caption {
                .card-description {
                    &:after {
                        @extend .d-none;
                    }
                }
            }
        }
    }

/*  service_overview
    ========================================================================== */
    .service_overview {
        .item {
            .card {
                .card-image {
                    position: relative;
                    border-radius: 3px;
                    overflow: hidden;
                    &:before {
                        content: "\f002";
                        font-family: "Font Awesome 5 Pro";
                        position: absolute;
                        right: 15px;
                        bottom: 10px;
                        color: $white;
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 1;
                    }
                }
            }
        }
    }

/*  blog_overview
    ========================================================================== */
    .blog_overview {
        .item {
            .card {
                .card-body {
                    .card-subtitle {
                        @extend .d-none;
                    }
                }
            }
        }
    }

/*  werkwijze-blocks
    ========================================================================== */
    .werkwijze-blocks {
        max-width: 730px;
        margin: 30px auto;
        .container-holder {
            background-color: $gray-light;
        }
        .grid {
            max-width: 570px;
            margin: 0 auto;
            padding: 30px 0;
            .item {
                @include make-col(12);
                .card {
                    flex-direction: row;
                    .card-image {
                        max-width: 65px;
                        margin-right: 30px;
                        background: transparent;
                        font-size: 1rem;
                    }
                    .card-body {
                        padding-top: 0;
                        .card-caption {
                            .card-title {
                                margin-bottom: 0;
                            }
                            .card-description {
                                margin-bottom: 0;
                            }
                            .card-buttons {
                                @extend .d-none;
                            }
                        }
                    }
                }
            }
        }
    }


/*  trends-blocks
    ========================================================================== */
    .trends-blocks {
        padding: 0 !important;
        .grid {
            margin: 0;
            @include media-breakpoint-up(xl) {
                margin: 0 -80px;
            }
            .item {
                padding: 0;
                @include make-col(12);
                margin-bottom: 0;
                @include media-breakpoint-down(sm) {
                    margin-top: 5px;
                }
                .card {
                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                    }
                    .card-image {
                        @include media-breakpoint-up(md) {
                            width: 50%;
                        }
                        background: transparent;
                        font-size: 1rem;
                        .card-image-link {
                            position: relative;
                            &:before {
                                content: "\f002";
                                font-family: "Font Awesome 5 Pro";
                                position: absolute;
                                right: 15px;
                                bottom: 10px;
                                color: $white;
                                font-size: 20px;
                                font-weight: 300;
                                line-height: 1;
                            }
                        }
                    }
                    .card-body {
                        margin: auto;
                        @include media-breakpoint-down(sm) {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            padding: 15px;
                        }
                        @include media-breakpoint-up(xl) {
                            margin-right: 65px;
                        }
                        padding: 15px 40px;
                        .card-caption {
                            .card-title-link {
                                margin-bottom: 0;
                                text-decoration: none;
                                .card-title {
                                    font-size: 13px;
                                    @include media-breakpoint-down(sm) {
                                        color: $white !important;
                                    }
                                }
                            }
                            .card-subtitle {
                                font-size: 24px;
                                @include media-breakpoint-down(sm) {
                                    margin-bottom: 0;
                                    color: $white !important;
                                }
                            }
                            @include media-breakpoint-down(sm) {
                                .card-description {
                                    display: none;
                                }
                            }
                        }
                        .card-buttons {
                            .card-btn {
                                min-width: auto;
                                padding: 0;
                                background: none;
                                border: none;
                                color: $gray;
                                &:hover {
                                    color: $black;
                                }
                                @include media-breakpoint-down(sm) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            @include media-breakpoint-up(md) {
                .item:nth-child(even) {
                    .card {
                        .card-image { order: 1;}
                        .card-body {
                            order: 0;
                            @include media-breakpoint-up(xl) {
                                margin-left: 65px;
                            }
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }


/*  team-blocks
    ========================================================================== */
    .team-blocks {
        &.grid {
            .item {
                @include make-col(3);
				@media(max-width:767px){
					padding-left: 5px;
					padding-right: 5px;
				}
            }
        }
        .item {
            margin-bottom: 0 !important;
            .card {
                .card-image {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    border-radius: 100%;
                    overflow: hidden;
                    .card-img {
                        width: 100%;
                        height: auto;
                        border-radius: 100%;
                        overflow: hidden;
                    }
                }
                .card-body {
                    .card-caption {
                        text-align: center;
                        .card-title {
                            margin-bottom: 0;
                            padding: 0;
                            color: $white;
                            font-size: 13px;
                            text-align: center;
                        }
                    }
                    .card-buttons { display: none;}
                }
            }
        }
    }
