.eyecatcher-video {
	position: relative;
	max-height: 100vh;
	.video-container {
		@include media-breakpoint-up(md) {
			padding-bottom: 56.25%;
			height: 0;
		}

		.container-holder {
			> p { display: none;}
			@include media-breakpoint-up(md) {
				> p { display: block;}
				.owl-carousel { display: none;}
			}
		}

		@include media-breakpoint-up(xl) {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
		p { margin-bottom: 0;}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			max-height: 100vh;
			z-index: 1;
		}
		.caption {
			@include media-breakpoint-down(sm) {
				display: none;
			}
			z-index: 2;
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 15px;
			@include media-breakpoint-up(sm) {
				left: 40px;
				bottom: 40px;
			}
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			.caption-subtitle {
				order: 0;
				margin-bottom: 0;
				color: $white;
				font-size: 16px;
				line-height: 1;
				@include media-breakpoint-up(xl) {
					font-size: 20px;
				}
			}
			.caption-title {
				margin-bottom: 0;
				order: 1;
				color: $white;
				font-size: 40px;
				line-height: 1;
				@include media-breakpoint-up(xl) {
					font-size: 60px;
				}
			}
			.btn {
				order: 2;
				@extend .btn-outline-secondary;
			}
		}
	}
}

.eyecatcher-video {
	.owl-carousel {
		.owl-nav {
			@extend .d-none;
		}
	}
}

/* owl-nav */
.owl-carousel {
	.owl-nav {
		display: flex;
		left: 15px;
		right: auto;
		bottom: 15px;
		margin-bottom: 0;
		.owl-prev,
		.owl-next {
			position: static;
			width: auto;
			height: auto;
			margin-right: 10px;
			font-size: 36px;
			line-height: 1;
			text-shadow: none;
			&:hover {
				color: $gray;
			}
		}
	}
}

// banner-container
.banner-container {
	.owl-carousel {
		height: calc(100vh - 185px);
		min-height: auto;
		max-height: 1080px;
		.item {
			height: calc(100vh - 185px) !important;
			min-height: auto;
			max-height: 1080px;
    		padding: 0 !important;
			.owl-caption {
				z-index: 2;
				position: absolute;
				left: 15px;
				right: 15px;
				bottom: 15px;
				@include media-breakpoint-up(sm) {
					left: 40px;
					bottom: 40px;
				}
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				.owl-subtitle {
					order: 0;
					margin-bottom: 0;
					color: $white;
					font-size: 16px;
					line-height: 1;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
						font-size: 20px;
					}
				}
				.owl-title {
					margin-bottom: 0;
					order: 1;
					color: $white;
					font-size: 40px;
					line-height: 1;
					text-shadow: none;
					@include media-breakpoint-up(xl) {
						font-size: 60px;
					}
				}
				.owl-btn {
					order: 2;
					@extend .btn-outline-secondary;
				}
			}
		}
	}
}
