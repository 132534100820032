.btn {
    min-width: 240px;
    padding: 0.407rem 0.75rem;
    border-radius: 18px;
    background-color: $gray-dark;
    border-color: $gray-dark;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    /*&:hover {
        background-color: transparent;
        border-color: $white;
        color: $white;
    }*/

    /* btn-primary */
    &.btn-primary {
        background-color: $gray;
        border-color: $gray;
        color: $white;
        &:hover {
            background-color: transparent;
            border-color: $gray;
            color: $gray;
        }
    }

    /* btn-outline-primary */
    &.btn-outline-primary {
        background-color: transparent;
        border-color: $gray;
        color: $gray;
        &:hover {
            background-color: $gray;
            border-color: $gray;
            color: $white;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        background-color: $white;
        border-color: $white;
        color: $gray;
        &:hover {
            background-color: transparent;
            border-color: $white;
            color: $white;
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        background-color: transparent;
        border-color: $white;
        color: $white;
        &:hover {
            background-color: $white;
            border-color: $white;
            color: $gray;
        }
    }
}