/*  main (above video)
    ========================================================================== */
    .main {
        position: relative;
        background-color: $white;
    }

/*  container-narrow
    ========================================================================== */
    .container-narrow {
        .container-holder {
            max-width: 540px;
            margin: 0 auto;
        }
    }

    /* py-narrow */
    .py-narrow {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

/*  content
    ========================================================================== */
    .content-padding {
        padding: $content-padding;
    }
    .content {
        &.bg-dark {
            a:not(.btn):not(.card-btn) {
                color: $white;
                text-decoration: underline;
                &:hover {
                    color: $gray-light;
                }
            }
        }
    }

/*  album
    ========================================================================== */
    .album {
        .container-fluid {
            padding: 0 3px;
            .gallery-grid {
                margin: 0 -3px -6px -3px;
                .gallery-item {
                    @include media-breakpoint-down(md) {
                        @include make-col(6);
                    }
                    @include make-col(4);
                    margin-bottom: 6px;
                    padding-left: 3px;
                    padding-right: 3px;
                    .gallery-link {
                        &:before {
                            content: "\f002";
                            font-family: "Font Awesome 5 Pro";
                            position: absolute;
                            right: 15px;
                            bottom: 10px;
                            color: $white;
                            font-size: 20px;
                            font-weight: 300;
                            line-height: 1;
                        }
                        .gallery-caption {
                            top: auto;
                            padding: 15px;
                            background: transparent;
                            font-size: 24px;
                            line-height: 1.2;
                            font-family: $font-family-didact-gothic;
                        }
                        .gallery-img {
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
        .owl-nav {
            @extend .d-none;
        }
    }

/*  inspiration
    ========================================================================== */
    .inspiration {
        padding-bottom: 3vh;
    }

/*  trends
    ========================================================================== */
    .trends {
        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
            background-color: $gray-light !important;
        }
        .container-narrow {
            padding: 40px 15px 30px 15px !important;
        }
        h1, h2, h3, h4, h5, h6, p {
            color: $black !important;
        }
        .container {
            @include media-breakpoint-up(xl) {
                max-width: 980px;
            }
            background-color: $white;
            @include media-breakpoint-down(sm) {
                background-color: $gray-light !important;
            }
        }
    }

/*  spacer
    ========================================================================== */
    .spacer {
        margin: 40px 6px;
        min-height: 75vh;
        .owl-carousel {
            .item {
                align-items: flex-end;
                min-height: 75vh;
                padding: 15px 0;
                border-radius: 4px;
                .owl-container {
                    margin-left: 100px;
                    @include media-breakpoint-up(md) {
                        margin-left: 150px;
                    }
                    margin-right: 0;
                    .owl-caption {
                        color: $white;
                        .owl-title {
                            margin-bottom: 0;
                            font-size: 13px;
                            text-shadow: none;
                        }
                        .owl-subtitle {
                            margin-bottom: 0;
                            font-size: 24px;
                            text-shadow: none;
                        }
                    }
                }
            }
        }
    }