.footer {
    position: relative;
    color: $white;
    font-size: 15px;
    h1, h2, h3, h4, h5, h6 { color: $white;}
    h3 { font-size: 24px;}
    h5 { font-size: 16px;}
    h6 {
        margin-bottom: 0.25rem;
        font-size: 13px;
    }
    a { color: $white;}

/*  page-ending
    ========================================================================== */
    .page-ending {
        padding: 6vh 0 10vh 0;
        background-color: $black;
        text-align: center;
        .team-blocks {
            margin-top: 30px;
            margin-bottom: 30px;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 30px 0 0 0;
            padding: 0;
            list-style: none;
            li { margin: 5px 15px;
                a {
                    background-color: transparent;
                    border-color: $white;
                    color: $white;
                    &:hover {
                        background-color: $white;
                        border-color: $white;
                        color: $gray;
                    }
                }
            }
        }
    }

/*  address
    ========================================================================== */
    .address {
        padding: 6vh 0;
        background-color: $gray;
        .socials {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin-right: 15px;
                a {
                    font-size: 24px;
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }

/*  sitelinks
    ========================================================================== */
    .sitelinks {
        padding: 6vh 0;
        background-color: $gray-dark;
        font-size: 13px;
        h5 { margin-bottom: 1rem;}
        .list {
            margin-bottom: 1rem;
            .list-item {
                margin-top: 5px;
            }
        }
    }

/*  copyright
    ========================================================================== */
    .copyright {
        padding: 6vh 0 25px 0;
        background-color: $gray-dark;
        font-size: 13px;
        text-align: center;        
        .list {
            justify-content: center;
            .list-item {
                margin-right: 20px;
            }
        }
    }

/*  fixed-footer-bar
    ========================================================================== */
    .fixed-footer-bar {
        @include media-breakpoint-up(xl) {
            display: none !important;
        }
        z-index: 998;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px 0;
        background-color: $gray;
        .footer_logolink {
            .list {
                justify-content: center;
                .list-item {
                    margin: 0 15px;
                    font-size: 20px;
                    .link {
                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
        }
    }

}