/////////// VENDOR VARS CONFIGURATION ////////////
// Theme vendor vars
@import './0_config/vendor-variables';
// Base vendor vars
@import '~framework/assets/scss/Dev/0_config/vendor-variables';

/////////// VENDOR SETUP ////////////
// Base setup (bootstrap: function/mixins/variables)
@import '~framework/assets/scss/Dev/setup';

/////////// WEBSITE VARS CONFIGURATION ////////////
// Theme website vars
@import './0_config/website-variables';
// Base website vars
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/content';
	@import './2_layout/eyecatcher';
	@import './2_layout/footer';
	@import './2_layout/header';

	@import './3_components/buttons';
	@import './3_components/cards';

	@import './4_page_block/collections';
	@import './4_page_block/gallery-grid';
	@import './4_page_block/form';

	@import './5_utilities/animate';

	@import './6_theme/module.blog';
	@import './6_theme/module.projecten';
	@import './6_theme/page.home';
	@import './6_theme/page.landing';

}
